.yellow-circle {
  position: relative;
  z-index: 0;
}

.yellow-circle::after {
  content: '';
  background-color: #FFE025;
  top: -10px;
  right: -70px;
  left: -70px;
  bottom: -150px;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.challenge-box::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 0;
}
.challenge-box > * {
  position: relative;
  z-index: 1;
}